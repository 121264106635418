import {questionsMeta} from "../structure";
import utils from "../utils";
import stateManager from "../stateManager";
import axios from "axios";

export const task_9_1 = function(currentState, stt){
    const $page = $('.page-question');
    const $buttonSkip = $page.find('.button.next');
    const $input = $page.find('.task input')

    let isSubmitActive = true;

    let meta = questionsMeta[currentState];

    $page.find('.footer').addClass('action');
    $buttonSkip.text(meta.button).removeClass('hollow').addClass('blue').addClass('disabled');

    $input.focus();

    const validateEmail = (email) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const validate = () => {
        if(validateEmail($input.val())){
            $input.removeClass('invalidated');
            $buttonSkip.removeClass('disabled');
        } else{
            $input.addClass('invalidated');
            $buttonSkip.addClass('disabled');
        }
        return false;
    }

    $input.on('input', validate);

    $buttonSkip.on('click', function(){
        if(!$(this).hasClass('disabled')){
            finish($input.val());
        }
    });

    const finish = function (answer){

        if(isSubmitActive) {
            isSubmitActive = false;

            axios.post(process.env.API_URL + 'api/test/finish', {
                uuid: utils.getFromLS('uuid'),
                email: answer,
                url: document.location.origin + '/template/pdf-results'
            })
                .then(function (response) {
                    response = response.data;

                    if(response.status){
                        stt.closeMicrophone();
                        stateManager.goToNextState();
                    }else{
                        utils.notifyError(response.message);
                    }
                    isSubmitActive = true;

                })
                .catch(function (error) {
                    isSubmitActive = true;
                    console.log(error);
                    utils.showNotification('Не удалось установить соединение с сервером. Проверьте подключение к интернету');
                });
        }
    };

    const startTask = () => {
        $page.find('.general .title').fadeOut(200, () => $page.find('.general .title').html(utils.formatTitle(meta.title[1])).fadeIn(200));
        $page.find('.footer .button').addClass('active');
        $page.find('.question').addClass('active');
        $('#answer').animate({height: window.questionHeight}, 400, function(){
            $(this).height('auto');
        });
    };

    startTask();

};