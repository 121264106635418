export const structure = [
    'question/1/1',
    'question/2/1',
    'question/2/2',
    'question/3/1',
    'question/4/1',
    'question/5/1',
    'question/6/1',
    'question/7/1',
    'question/8/1',
    'question/9/1',
    // 'contacts',
    'results'
];

export const questionsMeta = {
    // 'question/0/1': {
    //     'subcount': false,
    //     'audio': false,
    //     'title': 'Сколько вам лет?',
    //     'time': 20,
    //     'category': 'Возраст',
    //     'button': 'Продолжить'
    // },
    'question/1/1': {
        'subcount': false,
        'audio': [
            'D1_1.mp3',
            'D1_2.mp3',
            'D1_3.mp3',
        ],
        'title': [
            'Сейчас я&nbsp;буду называть вам слова. Постарайтесь их&nbsp;запомнить',
            'Назовите слова, которые вы&nbsp;запомнили'
        ],
        'time': 40,
        'category': 'Память'

    },
    'question/2/1': {
        'subcount': 5,
        'audio': 'D21_1.mp3',
        'title': [
            'Последовательно вычитайте из&nbsp;ста семь пять раз подряд. Ваша задача назвать все пять чисел, которые у&nbsp;вас получились',
            'Вычтите {7} из {100}',
            'Вычтите {7} из получившегося числа',
            'Вычтите {7} из получившегося числа',
            'Вычтите {7} из получившегося числа',
            'Вычтите {7} из получившегося числа',
            ],
        'time': 7,
        'category': 'Внимание'
    },
    'question/2/2': {
        'subcount': 3,
        'audio': 'D22_1.mp3',
        'title': [
            'Перед вами будет представлена таблица, на&nbsp;которой расположены цифры от&nbsp;1&nbsp;до&nbsp;25. Ваша задача нажать на&nbsp;каждое число в&nbsp;порядке возрастания',
            'Нажмите на&nbsp;цифры от&nbsp;1&nbsp;до&nbsp;25&nbsp;в порядке возрастания'
        ],
        'time': 180,
        'category': 'Концентрация',
        'dict': {
            'nextDigit': 'Следующая цифра:'
        }
    },
    'question/3/1': {
        'subcount': 6,
        'audio': 'D31_1.mp3',
        'title': [
            'В&nbsp;этом задании вам будет дано определение и&nbsp;набор из&nbsp;пяти слов. Ваша задача выбрать 2&nbsp;слова из&nbsp;предложенных, которые наиболее точно характеризуют заданное определение',
            'Выберите 2&nbsp;слова, наиболее соответствующих понятию'
        ],
        'subjects': [
            'Игра',
            'Спорт',
            'Город',
            'Куб',
            'Деление',
            'Кольцо',
        ],
        'options': [
            ['карты', 'игроки', 'штрафы', 'наказания', 'правила'],
            ['медаль', 'оркестр', 'состязание', 'победа', 'стадион'],
            ['автомобилист', 'здание', 'толпа', 'улица', 'велосипед'],
            ['углы', 'чертеж', 'сторона', 'камень', 'дерево'],
            ['класс', 'делимое', 'карандаш', 'делитель', 'бумага'],
            ['диаметр', 'алмаз', 'проба', 'округлость', 'печать'],
        ],
        'time': 60,
        'category': 'Мышление'
    },
    'question/4/1': {
        'subcount': 8,
        'audio': 'D41_1.mp3',
        'title': [
            'Перед вами представлено два изображения. Вам необходимо выбрать номер элемента на&nbsp;картинке {Б}, который соответствует элементу, изображенному на&nbsp;картинке {А}',
            'Выберите вариант с&nbsp;картинки {Б}, который совпадает с&nbsp;картинкой {А}'
        ],
        'time': 60,
        'category': 'Пространственная ориентация'
    },
    'question/5/1': {
        'subcount': false,
        'audio': 'D51_1.mp3',
        'title': [
            'Назовите максимальное количество слов, начинающихся на&nbsp;букву {С}, за&nbsp;исключением имен собственных, чисел или однокоренных слов',
            'Назовите максимальное количество слов, начинающихся на&nbsp;букву {С}'
        ],
        'time': 60,
        'category': 'Речь'
    },
    'question/6/1': {
        'subcount': false,
        'audio': 'D61_1.mp3',
        'audioMobile': 'D61_2.mp3',
        'audioLetters': 'D61_3.mp3',
        'title': [
            'Сейчас вы&nbsp;услышите ряд букв. Каждый раз, когда слышите букву {А}, нажимайте пробел. Если слышите другую букву, ничего нажимать не&nbsp;нужно',
            'Нажимайте на&nbsp;пробел каждый раз, как слышите букву {А}'
        ],
        'titleMobile': [
            'Сейчас вы&nbsp;услышите ряд букв. Каждый раз, когда слышите букву {А}, нажимайте на&nbsp;синюю кнопку. Если слышите другую букву, ничего нажимать не&nbsp;нужно',
            'Нажимайте на&nbsp;синюю кнопку каждый раз, как слышите букву {А}'
        ],
        'options': [
            [0, 900],
            [901, 1054],
            [1935, 804],
            [2758, 1147],
            [3901, 933],
            [4822, 940],
            [5762, 878],
            [6662, 817],
            [7500, 1083],
            [8602, 817],
            [9434, 840],
            [10275, 1000],
            [11270, 860],
            [12120, 900],
            [13030, 770],
            [13790, 910],
            [14680, 860],
            [15550, 1050],
            [16580, 890],
            [17470, 918],
            [18395, 814],
            [19220, 1010],
            [20250, 827],
            [21071, 836],
            [21900, 708],
            [22616, 956],
            [23572, 827],
        ],
        'letterPositions': [2,6,7,12,14,18,19,20,22,26],
        'time': 24.4,
        'category': 'Регуляция и контроль'
    },
    'question/7/1': {
        'subcount': false,
        'audio': 'D71_1.mp3',
        'title': [
            'Перед вами представлены карточки с&nbsp;8&nbsp;цветами. Выбирайте те, к&nbsp;которым вы&nbsp;испытываете большую симпатию, по&nbsp;сравнению с&nbsp;остальными, пока все карточки не&nbsp;закончатся',
            'Выберите последовательно понравившиеся карточки с&nbsp;цветами'
        ],
        'options': [
            ['#f24725', 3],
            ['#353299', 1],
            ['#974b00', 6],
            ['#710d9d', 5],
            ['#95a3a4', 0],
            ['#000000', 7],
            ['#fffe64', 4],
            ['#01803d', 2],

        ],
        'time': 60,
        'category': 'Психологическое состояние'
    },
    'question/8/1': {
        'subcount': false,
        'audio': 'D81_1.mp3',
        'title': [
            'В&nbsp;начале тестирования я&nbsp;просила запомнить вас 10&nbsp;слов. Пожалуйста, назовите слова, которые вы&nbsp;помните',
            'Назовите 10&nbsp;слов, которые были в&nbsp;начале теста'
        ],
        'time': 30,
        'category': 'Отсроченная память'
    },
    'question/9/1': {
        'subcount': false,
        'audio': 'D91_1.mp3',
        'title': [
            'Укажите, пожалуйста, адрес вашей электронной почты, на&nbsp;который я&nbsp;отправлю вам результаты',
            'Укажите адрес вашей электронной почты'
        ],
        'time': 0,
        'category': 'E-mail',
        'button': 'Отправить'
    }
}